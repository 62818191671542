<script lang="ts" setup>
import type { LottiePlayer } from 'lottie-web'
import { storeToRefs } from 'pinia'
import { useRoot } from '~/store'
import type { LordIconSource, LordIconTrigger } from '~/types/assets'

const props = defineProps<{
  src: LordIconSource
  revert?: boolean
  trigger?: LordIconTrigger
  delay?: number
  size?: number | string
  minimal?: boolean
  loading?: string
}>()

const { theme } = storeToRefs(useRoot())

const colors = computed(() => {
  if (props.minimal) {
    return 'primary:#000000,secondary:#000000'
  } else {
    return `primary:${props.revert ? theme.value?.hexPrimary : theme.value?.hexSecondary},secondary:${
      props.revert ? theme.value?.hexSecondary : theme.value?.hexPrimary
    }`
  }
})

const attributes = computed(() => ({
  src: `https://s3.rottigni.tech/static/lord-icon/wired-outline/lord-icon-${props.src}.json`,
  trigger: props.trigger,
  style:
    typeof props.size === 'number'
      ? {
          height: props.size ? `${props.size}px` : '100%',
          width: props.size ? `${props.size}px` : '100%'
        }
      : {},
  class: typeof props.size === 'string' ? props.size : undefined,
  delay: props.delay,
  colors: colors.value,
  loading: props.loading || false
}))
</script>

<template lang="pug">
lord-icon.flex-shrink-0(v-bind="attributes")
</template>
